import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  CardActions,
  Divider,
  createTheme,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import { useAuthContext } from "../../providers/AuthProvider";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#d32f2f",
    },
    background: {
      default: "#f4f6f8",
    },
  },
  typography: {
    fontFamily: "Roboto, Arial",
    h4: {
      fontWeight: 700,
    },
    body2: {
      fontSize: "0.9rem",
      color: "#6b6b6b",
    },
  },
});

const StyledCard = styled(Card)({
  borderRadius: "16px",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
});

const panelLinks = [
  {
    title: "panel.gogig.in",
    url: "https://panel.gogig.in:18075/78be58c5",
    description:
      "This panel has blogs.gogig.in, spotrue.com, watertankpainting.com, mumbaiairportadvertising.com, ads.gingermediagroup.com",
    credentials: { username: "Gogig", password: "Admin123" },
    websites: [
      "blogs.gogig.in",
      "spotrue.com",
      "watertankpainting.com",
      "mumbaiairportadvertising.com",
      "ads.gingermediagroup.com",
    ],
  },
  {
    title: "aapanel.gogig.in",
    url: "https://aapanel.gogig.in:28364/592720ed",
    description: "This panel has iconsbehindbrands.com, gingercup.com",
    credentials: { username: "Gogig", password: "Admin123" },
    websites: ["iconsbehindbrands.com", "gingercup.com"],
  },
];

const CloudPanelLinks = () => {
  const { userDetails } = useAuthContext();

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard: " + text);
  };

  const hasAccess = userDetails?.role === "gogig-role-a1";
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          padding: 4,
          backgroundColor: theme.palette.background.default,
          minHeight: "100vh",
        }}
      >
        <Typography variant="h4" sx={{ textAlign: "center", marginBottom: 4 }}>
          Cloud Panel Links
        </Typography>
        <Grid container spacing={4}>
          {panelLinks.map((panel, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <StyledCard>
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    {panel.title}
                  </Typography>
                  <Divider sx={{ marginY: 2 }} />
                  <Typography variant="body2" paragraph>
                    {panel.description}
                  </Typography>
                  {hasAccess && (
                    <Box sx={{ marginTop: 2 }}>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", marginBottom: 1 }}
                      >
                        Login Credentials:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          marginBottom: 1,
                          flexDirection: "column",
                        }}
                      >
                        <Tooltip title="Copy Username">
                          <Typography
                            onClick={() =>
                              handleCopy(panel.credentials.username)
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            Username: {panel.credentials.username}{" "}
                            <ContentCopyIcon
                              sx={{ fontSize: 16, marginLeft: 1 }}
                            />
                          </Typography>
                        </Tooltip>
                        <Tooltip title="Copy Password">
                          <Typography
                            onClick={() =>
                              handleCopy(panel.credentials.password)
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            Password: {panel.credentials.password}{" "}
                            <ContentCopyIcon
                              sx={{ fontSize: 16, marginLeft: 1 }}
                            />
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Box>
                  )}
                </CardContent>
                <CardActions
                  sx={{
                    padding: 2,
                    flexDirection: "column",
                    alignItems: "stretch",
                  }}
                >
                  {hasAccess && (
                    <Button
                      variant="contained"
                      color="primary"
                      href={panel.url}
                      target="_blank"
                      fullWidth
                      sx={{
                        marginBottom: 2,
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        textAlign: "center",
                      }}
                    >
                      Open Panel
                    </Button>
                  )}

                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {panel.websites.map((website, idx) => (
                      <Button
                        key={idx}
                        variant="outlined"
                        color="secondary"
                        href={`https://${website}`}
                        target="_blank"
                        sx={{
                          flexGrow: 1,
                          minWidth: "45%",
                          whiteSpace: "normal",
                          textAlign: "center",
                          padding: "6px 8px",
                        }}
                      >
                        {website}
                      </Button>
                    ))}
                  </Box>
                </CardActions>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default CloudPanelLinks;
