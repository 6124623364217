import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GroupsIcon from "@mui/icons-material/Groups";
import { useAuthContext } from "../../providers/AuthProvider";
import UnauthorizedMessage from "./UnauthorizedMessage";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, userDetails } = useAuthContext();

  const handleAdminPanelClick = () => {
    navigate("/wordpress-admin");
  };

  const handleServicesClick = () => {
    navigate("/services");
  };

  const handleVendorsClick = () => {
    navigate("/vendors");
  };

  const handleUploadClick = () => {
    navigate("/upload");
  };

  const IconButtonStyle = styled(Button)(({ theme }) => ({
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
    margin: "0 40px",
    transition: "transform 0.1s",
    "&:hover": {
      backgroundColor: "transparent",
      transform: "scale(1.2)",
    },
  }));

  const hasAccess = userDetails?.role === "gogig-role-a1";

  return isAuthenticated ? (
    <Box
      sx={{
        height: `calc(100vh - 90px)`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {hasAccess && (
            <IconButtonStyle onClick={handleAdminPanelClick}>
              <AdminPanelSettingsIcon sx={{ fontSize: 100 }} />
              <Typography marginTop={1} variant="body1" fontWeight="bold">
                Admin Panel
              </Typography>
            </IconButtonStyle>
          )}
          <IconButtonStyle onClick={handleServicesClick}>
            <MiscellaneousServicesIcon sx={{ fontSize: 100 }} />
            <Typography marginTop={1} variant="body1" fontWeight="bold">
              Services
            </Typography>
          </IconButtonStyle>
          <IconButtonStyle onClick={handleVendorsClick}>
            <GroupsIcon sx={{ fontSize: 100 }} />
            <Typography variant="body1" marginTop={1} fontWeight="bold">
              Vendors
            </Typography>
          </IconButtonStyle>
          <IconButtonStyle onClick={handleUploadClick}>
            <CloudUploadIcon sx={{ fontSize: 100 }} />
            <Typography variant="body1" marginTop={1} fontWeight="bold">
              Uploads
            </Typography>
          </IconButtonStyle>
        </Box>
      </Box>
    </Box>
  ) : (
    <UnauthorizedMessage />
  );
};

export default Home;
